import React from 'react';
import { IRadioFieldProps } from '../types';
import Radio from '../../radio';
import { DEFAULT_REQUIRED_ERROR } from '../index';
import getFieldError from '../utils/getFieldError';

const RadioField: React.FC<IRadioFieldProps> = props => {
	const {
		field: { fieldName, defaultValue, value, hotReload, onChange, onBlur, isRequired, errorMessage, ...rest },
		formHook: {
			register,
			setValue,
			formState: { errors },
		},
	} = props;
	const message: string | undefined = getFieldError(fieldName, errors);
	const fieldRegistration = register(fieldName, {
		required: isRequired ? errorMessage || DEFAULT_REQUIRED_ERROR : false,
	});
	const registrationWithUserProps = {
		...fieldRegistration,
		onChange: async (ev: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
			if (onChange) onChange(ev);
			await fieldRegistration.onChange(ev);
		},
		onBlur: async (ev: React.FocusEvent<HTMLInputElement>): Promise<void> => {
			if (onBlur) onBlur(ev);
			await fieldRegistration.onBlur(ev);
		},
	};

	React.useEffect(() => {
		//if (value === undefined) return;
		setValue(fieldName, value);
	}, [fieldName, value, setValue]);

	React.useEffect(() => {
		//if (defaultValue === undefined || !hotReload) return;
		setValue(fieldName, defaultValue);
	}, [fieldName, defaultValue, hotReload, setValue]);

	return (
		<Radio
			{...{ ...rest, name: fieldName }}
			value={value}
			defaultValue={defaultValue}
			isInvalid={!!message}
			isRequired={isRequired}
			errorMessage={message}
			{...registrationWithUserProps}
		/>
	);
};

export default React.memo(RadioField);
