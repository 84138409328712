import React, { createContext, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { IForm } from '../../../../../../../libs/uiKit/fieldsBuilder/types';
import { IDaDataValue } from '../../../../../../../libs/uiKit/daData/types';
import Button from '../../../../../../../libs/uiKit/button';
import { RADIO_BOOLEAN } from '../../../../../../../libs/utils/staticData';
import {useCreateAgreementContext} from "../../../../ui/context";

type TitleContext = {
	form: IForm;
};

export type TitleContextProps = {
	children?: React.ReactNode;
	number: number;
	defaultValues?: TitleField;
	arrayName: string;
};

export type TitleField = {
	address?: IDaDataValue | null;
	addressEgrn?: string;
	share?: number;
	subjectEncumbrances?: string;
	typeEncumbrances?: string;
	minors?: string;
	unregisterPersons?: string;
	litigation?: string;
	cadastralNumber?: string;
};

const TitleContext = createContext<TitleContext>({} as TitleContext);

export const useTitleContext = (): TitleContext => useContext(TitleContext);

const TitleContextProvider: React.FC<TitleContextProps> = props => {
	const { children, defaultValues, number, arrayName } = props;
	const formHook = useFormContext();

	const [stateAddressEgrn, setAddressEgrn] = React.useState<boolean>(false);

	const {
		isFieldsDisabled
	} = useCreateAgreementContext();

	const { address, addressEgrn, share, subjectEncumbrances, typeEncumbrances, minors, unregisterPersons, litigation, cadastralNumber } = defaultValues || {};

	const [subjectEncumbrancesValue] = formHook.watch([`${arrayName}[${number}].subjectEncumbrances`]);
	const isSubjectEncumbrances: boolean = subjectEncumbrancesValue === 'true';

	React.useEffect(() => {
		if (!addressEgrn) return;
		setAddressEgrn(true);
	}, [addressEgrn]);

	const form: IForm = React.useMemo(
		() => ({
			formHook,
			hotReload: true,
			isDisabled: isFieldsDisabled,
			fields: [
				{
					field: {
						fieldType: 'daData',
						fieldName: `${arrayName}[${number}].address`,
						daDataType: 'address',
						placeholder: 'Выберите адрес',
						isRequired: true,
						// validationType: 'addressWithFlat',
						defaultValue: address,
						errorMessage: 'Введите адрес',
					},
				},
				{
					field: {
						fieldType: 'input',
						fieldName: `${arrayName}[${number}].cadastralNumber`,
						placeholder: 'Кадастровый номер',
						validationType: 'cadastralNumber',
						defaultValue: cadastralNumber,
					},
				},
				{
					field: {
						fieldType: 'element',
						fieldName: `${arrayName}[${number}].showIsErgn`,
						element: (
							<Button appearance='link' onClick={(): void => setAddressEgrn(state => !state)}>
								Ввести адрес как в ЕГРН
							</Button>
						),
					},
				},
				{
					isHidden: !stateAddressEgrn,
					field: {
						fieldType: 'input',
						fieldName: `${arrayName}[${number}].addressEgrn`,
						placeholder: 'Ввод адреса из ЕГРН',
						defaultValue: addressEgrn,
					},
				},
				{
					field: {
						fieldType: 'range',
						fieldName: `${arrayName}[${number}].share`,
						placeholder: 'Доля титула',
						isRequired: true,
						min: 0,
						max: 100,
						step: 1,
						defaultValue: share,
						errorMessage: 'Выберите долю',
					},
				},
				{
					label: 'Наличие действующих обременений объекта залога (рента, арест, залог, доверительное управление)?',
					field: {
						fieldType: 'radio',
						fieldName: `${arrayName}[${number}].subjectEncumbrances`,
						defaultValue: subjectEncumbrances,
						items: RADIO_BOOLEAN,
					},
				},
				{
					isHidden: !isSubjectEncumbrances,
					field: {
						fieldType: 'input',
						fieldName: `${arrayName}[${number}].typeEncumbrances`,
						defaultValue: typeEncumbrances,
						placeholder: 'Вид обременения',
						isRequired: true,
						errorMessage: 'Введите вид обременения',
					},
				},
				{
					label: 'Наличие несовершеннолетних членов семьи, являющихся собственниками',
					field: {
						fieldType: 'radio',
						fieldName: `${arrayName}[${number}].minors`,
						defaultValue: minors,
						items: RADIO_BOOLEAN,
					},
				},
				{
					label: 'Наличие граждан, незарегистрированных в объекте залога, но имеющих право пользования (ранее выписанных в месте лишения свободы, призванных в армию и т.д.)',
					field: {
						fieldType: 'radio',
						fieldName: `${arrayName}[${number}].unregisterPersons`,
						defaultValue: unregisterPersons,
						items: RADIO_BOOLEAN,
					},
				},
				{
					label: 'Является ли предмет залога объектом судебных разбирательтв в прошлом или в настоящее время?',
					field: {
						fieldType: 'radio',
						fieldName: `${arrayName}[${number}].litigation`,
						defaultValue: litigation,
						items: RADIO_BOOLEAN,
					},
				},
			],
		}),
		[
			address,
			addressEgrn,
			arrayName,
			cadastralNumber,
			formHook,
			isSubjectEncumbrances,
			litigation,
			minors,
			number,
			share,
			stateAddressEgrn,
			subjectEncumbrances,
			typeEncumbrances,
			unregisterPersons,
		]
	);

	const value: TitleContext = React.useMemo(
		() => ({
			form,
		}),
		[form]
	);

	return <TitleContext.Provider value={value}>{children}</TitleContext.Provider>;
};

export default React.memo(TitleContextProvider);
