import React, { createContext, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from '@apollo/client';
import { useGlobalContext } from '../../../../../apps/main/ui/GlobalContext';
import useGetDicti from '../../../common/hooks/useGetDicti';
import {
	ChangeLogTableEnum,
	DictKeyEnum,
	GroupsQuery,
	GroupsQueryVariables,
	Restriction,
	RestrictionsMutation,
	RestrictionsMutationVariables,
	UsersQuery,
	UsersQueryVariables,
} from '../../../../../libs/api/graphqlTypes';
import { GROUPS, USERS } from '../../../../../libs/api/queries';
import { ISelectItem } from '../../../../../libs/uiKit/select/types';
import { IForm } from '../../../../../libs/uiKit/fieldsBuilder/types';
import { IEnumItem } from '../../../../../libs/uiKit/enums/types';
import useNotification from '../../../../widgets/notifier/ui/hooks/useNitification';
import { UPDATE_RESTRICTION } from '../../../../../libs/api/commands';
import {Text3} from "../../../../../libs/uiKit/globalStyledComponents";
import {TimeReloadIcon} from "../../../../../icons/ui/TimeReload";
import Button from "../../../../../libs/uiKit/button";
import {useUserRights} from "../../../common/hooks/useUserRights";
import {useNavigate} from "react-router-dom";

type SettingsContext = {
	form: IForm;
	onFormSubmit: () => void;
	isLoading: boolean;
	stateIsOpenHistory: boolean;
	setIsOpenHistory: (value: boolean) => void;
	onHistoryClick: () => void;
	historyData: any;
	isDisabledEdit: boolean;
};

type SettingForm = {
	autoscoring?: boolean;
	autoscoringLife?: boolean;
	autoscoringProperty?: boolean;
	autoscoringTitle?: boolean;
	declaration?: boolean;
	declarationLife?: boolean;
	declarationProperty?: boolean;
	declarationTitle?: boolean;
	ageLimit?: string;
	userOrGroup: ISelectItem;
	banks?: IEnumItem[];
	declarationAgeLimit?: string;
	declarationFloorMaterials?: IEnumItem[];
	declarationObjectType?: IEnumItem[];
	declarationSummLife?: number;
	declarationSummProperty?: number;
	declarationWallMaterials?: IEnumItem[];
	declarationYearOfBuild?: string;
	maxDiscount?: number;
	contragentMaxAge?: number;
	objectType?: IEnumItem[];
	summLife?: number;
	summProperty?: number;
	yearOfBuild?: string;
	comment: string;
};

export type Restrictions = {
	autoscoring?: boolean;
	autoscoringLife?: boolean;
	autoscoringProperty?: boolean;
	autoscoringTitle?: boolean;
	declaration?: boolean;
	declarationLife?: boolean;
	declarationProperty?: boolean;
	declarationTitle?: boolean;
	ageLimit?: string;
	banks?: string[];
	declarationAgeLimit?: string;
	declarationFloorMaterials?: string[];
	declarationObjectType?: string[];
	declarationSummLife?: number;
	declarationSummProperty?: number;
	declarationWallMaterials?: string[];
	declarationYearOfBuild?: string;
	maxDiscount?: number;
	contragentMaxAge?: number;
	objectType?: string[];
	summLife?: number;
	summProperty?: number;
	yearOfBuild?: string;
};

type UserGroupItem = { restrictions: Restriction[]; type: 'group' | 'user' } & ISelectItem;

const SettingsContext = createContext<SettingsContext>({} as SettingsContext);

export const useSettingsContext = (): SettingsContext => useContext(SettingsContext);

const SettingsContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const {
		user: { isSuper, restrictions, isAdmin, isStaffMember },
		routes: { error500 },
	} = useGlobalContext();

	const { rightsHandler } = useUserRights();
	const navigate = useNavigate();

	if(!(isSuper || isStaffMember)){
		navigate('/');
	}

	const { setNotification } = useNotification();
	const formHook = useForm<SettingForm>({ mode: 'onBlur' });

	const isDisabledEdit = !isSuper;

	const { data: selectPledgeTypes, loading: loadingPledgeTypes } = useGetDicti(DictKeyEnum.PropertyType);
	const { data: selectWallMaterial, loading: loadingWallMaterial } = useGetDicti(DictKeyEnum.WallMaterial);
	const { data: selectBanks, loading: loadingBanks } = useGetDicti(DictKeyEnum.CreditBank);

	const [stateIsOpenHistory, setIsOpenHistory] = React.useState<boolean>(false);
	const [stateHistoryData, setStateHistoryData] = React.useState<any>(null);

	const { data: dataGroups, loading: loadingGroups } = useQuery<GroupsQuery, GroupsQueryVariables>(GROUPS, { onError: error => error500(error) });
	const { data: dataUsers, loading: loadingUsers } = useQuery<UsersQuery, UsersQueryVariables>(USERS, { onError: error => error500(error) });
	const [updateRestriction, { loading: loadingUpdateRestriction }] = useMutation<RestrictionsMutation, RestrictionsMutationVariables>(UPDATE_RESTRICTION, {
		refetchQueries: ['users', 'groups'],
	});

	const selectGroups: UserGroupItem[] = (dataGroups?.groups || []).map(group => ({
		label: group?.name || '',
		type: 'group',
		value: `group-${group?.id}`,
		restrictions: (group?.restrictions || []).map(restriction => ({ tag: restriction?.tag, value: restriction?.value })),
	}));
	const selectUsers: UserGroupItem[] = (dataUsers?.users || []).map(user => ({
		label: user?.full_name && user?.username ? `${user?.full_name} (${user?.username})` : 'unknown',
		type: 'user',
		value: `user-${user?.id}`,
		restrictions: (user?.restrictions || []).map(restriction => ({ tag: restriction?.tag, value: restriction?.value })),
	}));
	const groupsUsers: UserGroupItem[] = React.useMemo(() => [...selectUsers, ...selectGroups], [selectGroups, selectUsers]);

	const [isAutoscoring, isAutoscoringLife, isAutoscoringPropery, isDeclaration, isDeclarationLife, isDeclarationProperty, userOrGroup] = formHook.watch([
		'autoscoring',
		'autoscoringLife',
		'autoscoringProperty',
		'declaration',
		'declarationLife',
		'declarationProperty',
		'userOrGroup'
	]);

	const {
		autoscoring,
		autoscoringLife,
		autoscoringProperty,
		autoscoringTitle,
		declaration,
		declarationLife,
		declarationProperty,
		declarationTitle,
		ageLimit,
		banks,
		declarationAgeLimit,
		declarationFloorMaterials,
		declarationObjectType,
		declarationSummLife,
		declarationSummProperty,
		declarationWallMaterials,
		declarationYearOfBuild,
		maxDiscount,
		contragentMaxAge,
		objectType,
		summLife,
		summProperty,
		yearOfBuild,
	} = (isSuper ? {} : restrictions) as Restrictions;

	const setupDefaultValueHandler = React.useCallback(
		(userOrGroup: ISelectItem | null) => {
			if (!userOrGroup) return;

			const curValues = formHook.getValues();
			Object.entries(curValues).forEach(([key, value]) => {
				if (key === 'userOrGroup') return;
				formHook.setValue(key as any, key === 'banks' ? [] : undefined);
			});

			const findRestriction: Restriction[] | undefined = groupsUsers.find(({ value }) => value === userOrGroup.value)?.restrictions;
			if (!findRestriction || !findRestriction.length) return;
			const restrictionsToObject: Restrictions = findRestriction.reduce<Restrictions>((prev, { tag, value }) => ({ ...prev, [tag || 'unknown']: value }), {});
			const restrictionsToSet = {
				...restrictionsToObject,
				banks: (restrictionsToObject.banks || []).map((bank: string) => selectBanks.find(({ value }) => value === bank)),
				objectType: (restrictionsToObject.objectType || []).map((object: string) => selectPledgeTypes.find(({ value }) => value === object)),
				declarationObjectType: (restrictionsToObject.declarationObjectType || []).map((object: string) => selectPledgeTypes.find(({ value }) => value === object)),
				declarationWallMaterials: (restrictionsToObject.declarationWallMaterials || []).map((material: string) => selectWallMaterial.find(({ value }) => value === material)),
				declarationFloorMaterials: (restrictionsToObject.declarationFloorMaterials || []).map((material: string) => selectWallMaterial.find(({ value }) => value === material)),
			};
			Object.entries(restrictionsToSet).map(([tag, value]) => formHook.setValue(tag as any, value));
		},
		[formHook, groupsUsers, selectBanks, selectPledgeTypes, selectWallMaterial]
	);

	const onHistoryClick = () => {
		const findUserOrGroup = groupsUsers.find(({ value }) => value === userOrGroup.value);
		if (!findUserOrGroup) return setNotification({ type: 'error', text: 'no user or group' });
		const id: string = findUserOrGroup.value.split('-')[1];
		setStateHistoryData({
			id: parseInt(id, 10),
			model: findUserOrGroup.type === 'user' ? ChangeLogTableEnum.RestrictionsForUser : ChangeLogTableEnum.RestrictionsForGroup,
		});
		setIsOpenHistory(true);
	}

	const onFormSubmit = formHook.handleSubmit(data => {
		debugger;
		const { userOrGroup, comment, ...rest } = data;
		const findUserOrGroup = groupsUsers.find(({ value }) => value === userOrGroup.value);
		if (!findUserOrGroup) return setNotification({ type: 'error', text: 'no user or group' });
		const id: string = findUserOrGroup.value.split('-')[1];
		if (!id) return setNotification({ type: 'error', text: 'no user or group id' });
		const restrictionsToSave: Restrictions = {
			...rest,
			banks: (data.banks || []).filter(bank => !!bank).map(bank => String(bank.value)),
			objectType: (data.objectType || []).map(object => String(object.value)),
			declarationObjectType: (data.declarationObjectType || []).map(object => String(object.value)),
			declarationWallMaterials: (data.declarationWallMaterials || []).map(material => String(material.value)),
			declarationFloorMaterials: (data.declarationFloorMaterials || []).map(material => String(material.value)),
		};

		updateRestriction({
			variables: {
				groupId: findUserOrGroup.type === 'group' ? id : null,
				userId: findUserOrGroup.type === 'user' ? id : null,
				restrictions: Object.entries(restrictionsToSave).map(([tag, value]) => ({ tag, value })),
				comment,
			},
		})
			.then(() => setNotification({ type: 'success', text: 'Успешно обновлено' }))
			.catch(error => setNotification({ type: 'error', text: error }));
	});

	const form: IForm = React.useMemo(
		() => ({
			formHook,
			hotReload: true,
			fields: [
				{
					field: {
						fieldType: 'select',
						fieldName: 'userOrGroup',
						placeholder: 'Агент / группа',
						isRequired: true,
						items: groupsUsers,
						onChange: setupDefaultValueHandler,
						isLoading: loadingUsers || loadingGroups,
						errorMessage: 'Выберите агента',
						isSearchAvailable: true,
						isClearable: true,
						grid:11,
					},
				},
				{
					field: {
						fieldType: 'element',
						element: <Button tooltip="История изменений" appearance="icon" icon={<TimeReloadIcon/>}
										 onClick={onHistoryClick}
										 style={{
											 display: "inline",
											 marginLeft: "-2rem",
										 }}
						/>,
						fieldName: 'historyClick',
						grid:1,
					}
				},
				{
					label: 'Исключить банки',
					field: {
						isDisabled: isDisabledEdit,
						fieldType: 'enums',
						fieldName: 'banks',
						placeholder: 'Поиск по банкам',
						isLoading: loadingBanks,
						items: selectBanks,
					},
				},
				{
					label: 'Максимально возможная скидка в %',
					field: {
						isDisabled: isDisabledEdit,
						fieldType: 'number',
						digitsAfterDot: 2,
						placeholder: 'Максимально возможная скидка в %',
						fieldName: 'maxDiscount',
						validationType: 'minMax',
						maxValue: maxDiscount || 100,
					},
				},
				{
					label: 'Максимально допустимый возраст заемщика/созаемщика',
					field: {
						isDisabled: isDisabledEdit,
						fieldType: 'range',
						fieldName: 'contragentMaxAge',
						errorMessage: 'Выберите возрастной лимит',
						placeholder: 'Возраст',
						min: 0,
						max: 100,
						step: 1,
						validationType: Number.isNaN(contragentMaxAge) ? undefined : 'minMax',
						maxValue: contragentMaxAge,
					},
				},
				{
					field: {
						isDisabled: isDisabledEdit,
						fieldType: 'checkbox',
						label: 'Автосогласование',
						fieldName: 'autoscoring',
					},
				},
				{
					isHidden: !isAutoscoring,
					field: {
						fieldType: 'checkbox',
						label: 'Автосогласование жизни',
						fieldName: 'autoscoringLife',
						isDisabled: isDisabledEdit || !isSuper && autoscoring && !autoscoringLife,
					},
				},
				{
					isHidden: !isAutoscoring || !isAutoscoringLife,
					label: 'Максимальная страховая сумма по риску жизнь',
					field: {
						isDisabled: isDisabledEdit,
						fieldType: 'number',
						digitsAfterDot: 2,
						isRequired: true,
						errorMessage: 'Введите максимальную страховую сумму по риску жизнь',
						placeholder: 'Страховая сумма по риску жизнь',
						fieldName: 'summLife',
						validationType: Number.isNaN(summLife) ? undefined : 'minMax',
						// maxValue: summLife,
					},
				},
				{
					isHidden: !isAutoscoring || !isAutoscoringLife,
					label: 'Возрастной лимит',
					field: {
						isDisabled: isDisabledEdit,
						fieldType: 'range',
						isRequired: true,
						errorMessage: 'Выберите возрастной лимит',
						placeholder: 'Возраст',
						fieldName: 'ageLimit',
						min: 0,
						max: 100,
						step: 1,
						validationType: Number.isNaN(ageLimit) ? undefined : 'minMax',
						maxValue: Number(ageLimit),
					},
				},
				{
					isHidden: !isAutoscoring,
					field: {
						fieldType: 'checkbox',
						label: 'Автосогласование имущества',
						fieldName: 'autoscoringProperty',
						isDisabled: isDisabledEdit || !isSuper && autoscoring && !autoscoringProperty,
					},
				},
				{
					isHidden: !isAutoscoring || !isAutoscoringPropery,
					label: 'Максимальная страховая сумма по риску имущество',
					field: {
						isDisabled: isDisabledEdit,
						fieldType: 'number',
						digitsAfterDot: 2,
						isRequired: true,
						errorMessage: 'Введите максимальную страховую сумму по риску имущество',
						placeholder: 'Страховая сумма по риску имущество',
						fieldName: 'summProperty',
						validationType: Number.isNaN(summProperty) ? undefined : 'minMax',
						maxValue: summProperty,
					},
				},
				{
					isHidden: !isAutoscoring || !isAutoscoringPropery,
					label: 'Разрешенные объекты залога',
					field: {
						isDisabled: isDisabledEdit,
						isRequired: true,
						errorMessage: 'Выберите объекты залога',
						fieldType: 'enums',
						fieldName: 'objectType',
						placeholder: 'Поиск по объектам залога',
						isLoading: loadingPledgeTypes,
						items: isSuper
							? selectPledgeTypes
							: selectPledgeTypes.map(pledge => {
									const { value } = pledge;
									if (!(objectType || []).includes(value)) return { ...pledge, isDisabled: true };
									return pledge;
							  }),
					},
				},
				{
					isHidden: !isAutoscoring || !isAutoscoringPropery,
					label: 'Год постройки с (включительно)',
					field: {
						isDisabled: isDisabledEdit,
						isRequired: true,
						errorMessage: 'Введите год постройки',
						fieldType: 'input',
						fieldName: 'yearOfBuild',
						mask: '9999',
						placeholder: 'Год постройки/приобретения с',
						validationType: Number.isNaN(yearOfBuild) ? undefined : 'minMax',
						minValue: Number(yearOfBuild),
					},
				},
				{
					isHidden: !isAutoscoring,
					columns: 3,
					columnGap: 2,
					field: {
						fieldType: 'checkbox',
						label: 'Автосогласование титула',
						fieldName: 'autoscoringTitle',
						isDisabled: isDisabledEdit || !isSuper && autoscoring && !autoscoringTitle,
					},
				},
				{
					field: {
						isDisabled: isDisabledEdit,
						fieldType: 'checkbox',
						fieldName: 'declaration',
						label: 'Доступность декларации',
					},
				},
				{
					isHidden: !isDeclaration,
					field: {
						fieldType: 'checkbox',
						label: 'Декларация жизни',
						fieldName: 'declarationLife',
						isDisabled: isDisabledEdit || !isSuper && declaration && !declarationLife,
					},
				},
				{
					isHidden: !isDeclaration || !isDeclarationLife,
					label: 'Максимальная страховая сумма по заявке',
					field: {
						isDisabled: isDisabledEdit,
						fieldType: 'number',
						digitsAfterDot: 2,
						isRequired: true,
						errorMessage: 'Введите максимальную страховую сумму',
						placeholder: 'Страховая сумма',
						fieldName: 'declarationSummLife',
						validationType: Number.isNaN(declarationSummLife) ? undefined : 'minMax',
						maxValue: declarationSummLife,
					},
				},
				{
					isHidden: !isDeclaration || !isDeclarationLife,
					label: 'Возрастной лимит',
					field: {
						isDisabled: isDisabledEdit,
						fieldType: 'range',
						isRequired: true,
						errorMessage: 'Выберите возрастной лимит',
						placeholder: 'Возраст',
						fieldName: 'declarationAgeLimit',
						min: 0,
						max: 100,
						step: 1,
						validationType: Number.isNaN(declarationAgeLimit) ? undefined : 'minMax',
						maxValue: Number(declarationAgeLimit),
					},
				},
				{
					isHidden: !isDeclaration,
					field: {
						fieldType: 'checkbox',
						label: 'Декларация имущества',
						fieldName: 'declarationProperty',
						isDisabled: isDisabledEdit || !isSuper && declaration && !declarationProperty,
					},
				},
				{
					isHidden: !isDeclaration || !isDeclarationProperty,
					label: 'Максимальная страховая сумма по заявке',
					field: {
						isDisabled: isDisabledEdit,
						fieldType: 'number',
						digitsAfterDot: 2,
						isRequired: true,
						errorMessage: 'Введите максимальную страховую сумму',
						placeholder: 'Страховая сумма',
						fieldName: 'declarationSummProperty',
						validationType: Number.isNaN(declarationSummProperty) ? undefined : 'minMax',
						maxValue: declarationSummProperty,
					},
				},
				{
					isHidden: !isDeclaration || !isDeclarationProperty,
					label: 'Год постройки с (включительно)',
					field: {
						isDisabled: isDisabledEdit,
						isRequired: true,
						errorMessage: 'Введите год постройки',
						fieldType: 'input',
						fieldName: 'declarationYearOfBuild',
						mask: '9999',
						placeholder: 'Год постройки/приобретения с',
						validationType: Number.isNaN(declarationYearOfBuild) ? undefined : 'minMax',
						minValue: Number(declarationYearOfBuild),
					},
				},
				{
					isHidden: !isDeclaration || !isDeclarationProperty,
					label: 'Объекты залога',
					field: {
						isDisabled: isDisabledEdit,
						isRequired: true,
						errorMessage: 'Выберите объекты залога',
						fieldType: 'enums',
						fieldName: 'declarationObjectType',
						placeholder: 'Поиск по объектам залога',
						isLoading: loadingPledgeTypes,
						items: isSuper
							? selectPledgeTypes
							: selectPledgeTypes.map(pledge => {
									const { value } = pledge;
									if (!(declarationObjectType || []).includes(value)) return { ...pledge, isDisabled: true };
									return pledge;
							  }),
					},
				},
				{
					isHidden: !isDeclaration || !isDeclarationProperty,
					label: 'Материал стен',
					field: {
						isDisabled: isDisabledEdit,
						isRequired: true,
						errorMessage: 'Выберите материал стен',
						fieldType: 'enums',
						fieldName: 'declarationWallMaterials',
						placeholder: 'Поиск по материалам стен',
						isLoading: loadingWallMaterial,
						items: isSuper
							? selectWallMaterial
							: selectWallMaterial.map(material => {
									const { value } = material;
									if (!(declarationWallMaterials || []).includes(value)) return { ...material, isDisabled: true };
									return material;
							  }),
					},
				},
				{
					isHidden: !isDeclaration || !isDeclarationProperty,
					label: 'Материал перекрытий',
					field: {
						isDisabled: isDisabledEdit,
						isRequired: true,
						errorMessage: 'Выберите материал перекрытий',
						fieldType: 'enums',
						fieldName: 'declarationFloorMaterials',
						placeholder: 'Поиск по материалам перекрытий',
						isLoading: loadingWallMaterial,
						items: isSuper
							? selectWallMaterial
							: selectWallMaterial.map(material => {
									const { value } = material;
									if (!(declarationFloorMaterials || []).includes(value)) return { ...material, isDisabled: true };
									return material;
							  }),
					},
				},
				{
					isHidden: !isDeclaration,
					field: {
						fieldType: 'checkbox',
						label: 'Декларация титула',
						fieldName: 'declarationTitle',
						isDisabled: isDisabledEdit || !isSuper && declaration && !declarationTitle,
					},
				},
				{
					field: {
						isDisabled: isDisabledEdit,
						fieldType: 'textarea',
						fieldName: 'comment',
						label: 'Комментарий',
						placeholder: 'Примечание (номер заявки на портале, обоснование изменений)',
						isRequired: true,
					}
				}
			],
		}),
		[
			ageLimit,
			autoscoring,
			autoscoringLife,
			autoscoringProperty,
			autoscoringTitle,
			banks,
			contragentMaxAge,
			declaration,
			declarationAgeLimit,
			declarationFloorMaterials,
			declarationLife,
			declarationObjectType,
			declarationProperty,
			declarationSummLife,
			declarationSummProperty,
			declarationTitle,
			declarationWallMaterials,
			declarationYearOfBuild,
			formHook,
			groupsUsers,
			isAutoscoring,
			isAutoscoringLife,
			isAutoscoringPropery,
			isDeclaration,
			isDeclarationLife,
			isDeclarationProperty,
			isSuper,
			loadingBanks,
			loadingGroups,
			loadingPledgeTypes,
			loadingUsers,
			loadingWallMaterial,
			maxDiscount,
			objectType,
			selectBanks,
			selectPledgeTypes,
			selectWallMaterial,
			setupDefaultValueHandler,
			summLife,
			summProperty,
			yearOfBuild,
		]
	);

	const value: SettingsContext = React.useMemo(
		() => ({
			form,
			onFormSubmit,
			isLoading: loadingUpdateRestriction,
			stateIsOpenHistory,
			setIsOpenHistory,
			onHistoryClick,
			historyData: stateHistoryData,
			isDisabledEdit
		}),
		[form, loadingUpdateRestriction, onFormSubmit]
	);

	return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
};

export default React.memo(SettingsContextProvider);
