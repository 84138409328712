import React from 'react';
import { useCreateUserContext } from './context';
import { Modal } from '../../../../../../libs/uiKit/modal';
import { FieldsBuilder } from '../../../../../../libs/uiKit/fieldsBuilder';
import Button from '../../../../../../libs/uiKit/button';
import {StyledButtonsGroup, StyledRemark} from './styles';
import ChangeLogModal from "../../changeLogModal";
import {TimeReloadIcon} from "../../../../../../icons/ui/TimeReload";
import {LogOutIcon} from "../../../../../../icons/ui/LogOut";
import {LogInIcon} from "../../../../../../icons/ui/LogIn";
import {useGlobalContext} from "../../../../../../apps/main/ui/GlobalContext";
import {useMutation} from "@apollo/react-hooks";
import {SIGN_IN_AS} from "../../../../../../libs/api/commands";
import {SignInAsMutation, SignInAsMutationVariables} from "../../../../../../libs/api/graphqlTypes";
import {BASE_URL} from "../../../../../../libs/utils/staticData";

const CreateUserUi: React.FC = () => {
	const { isOpen, onClose, onFormSubmit, isDisabledEdit, isLoadingUpdateUser, header, isEdit, form, id, fullName, remark, type } = useCreateUserContext();

	const {
		user: { isSuper, },
	} = useGlobalContext();

	const [signInAsUser, { loading: isLoadingSignInAsUser }] = useMutation<SignInAsMutation, SignInAsMutationVariables>(SIGN_IN_AS);

	const handleSignInAsUser = (id: number) => {
		signInAsUser({ variables: { id } }).then(() => {
			window.location.href = BASE_URL || '/';
		});
		onClose();
	}

	const [stateIsOpenHistory, setIsOpenHistory] = React.useState<boolean>(false);

	return (
		<>
			<Modal width={60} isOpen={isOpen} onClose={onClose} header={header}
						 afterHeaderContent={isEdit ?
							 <>
							 <Button tooltip="История изменений" onClick={() => setIsOpenHistory(true)} appearance="icon" icon={<TimeReloadIcon/>} style={{display: "inline"}} />
							 {isSuper ? <Button tooltip="Войти под пользователем" onClick={() => handleSignInAsUser(id)} appearance="icon" icon={<LogInIcon/>} style={{display: "inline"}} /> : null}
							 </>
							 : null}
			>
				<form onSubmit={onFormSubmit}>
					<FieldsBuilder {...form} />
					<StyledButtonsGroup>
						<Button isDisabled={isDisabledEdit} type='submit' isLoading={isLoadingUpdateUser} appearance='filled'>
							{isEdit ? 'Сохранить' : 'Создать'}
						</Button>
						<Button onClick={onClose} isLoading={isLoadingUpdateUser} appearance='transparent'>
							Отмена
						</Button>
					</StyledButtonsGroup>
					<StyledRemark>
						{type === 'kias' && <span>Тип <b style={{color: "darkgreen"}}>КИАС</b>.</span>}
						{type === 'user' && <span>Тип <b style={{color: "darkblue"}}>B2B</b>.</span>}
						{ remark }
					</StyledRemark>
				</form>
			</Modal>
			<ChangeLogModal
				onClose={() => setIsOpenHistory(false)}
				isOpen={stateIsOpenHistory}
				data={{id, model: 'USER'}}
				header={`Изменения пользователя ${fullName}`}
			/>
		</>
	);
};

export default React.memo(CreateUserUi);
