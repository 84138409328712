import React from 'react';
import { usePropertyContext } from './context';
import PropertyIgniter from '../../../widgets/property';
import { AddIcon } from '../../../../../../icons/ui/Add';
import Button from '../../../../../../libs/uiKit/button';
import { StyledSubjectHeader } from './styles';
import { Heading3 } from '../../../../../../libs/uiKit/globalStyledComponents';
import { TrashIcon } from '../../../../../../icons/ui/Trash';
import {useCreateAgreementContext} from "../../../ui/context";

const PropertyUi: React.FC = () => {
	const { properties, addPropertyHandler, deletePropertyHandler } = usePropertyContext();

	const {
		isFieldsDisabled
	} = useCreateAgreementContext();

	return (
		<>
			{properties.map(property => (
				<React.Fragment key={`property-${property.number}`}>
					{property.number > 0 && (
						<StyledSubjectHeader>
							<Heading3>Объект страхования {property.number + 1}</Heading3>
							<Button appearance='icon' onClick={(): void => deletePropertyHandler(property.number)} icon={<TrashIcon />} />
						</StyledSubjectHeader>
					)}
					<PropertyIgniter key={`property-${property.number}`} {...property} propertiesCount={properties.length} />
				</React.Fragment>
			))}
			{!isFieldsDisabled &&
				<Button appearance='link' iconBefore={<AddIcon />} onClick={addPropertyHandler}>
					Добавить объект страхования
				</Button>
			}
		</>
	);
};

export default React.memo(PropertyUi);
