import React, {createContext, useContext, useState} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {useQuery} from "@apollo/react-hooks";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { ContragentsForm } from '../../pages/contragents/ui/context';
import { JobForm } from '../../pages/job/ui/context';
import { BankForm } from '../../pages/bank/ui/context';
import { useGlobalContext } from '../../../../../apps/main/ui/GlobalContext';
import { AgreementSteps, CommandsCreateAgreement, formatAgreementDataToFormData, setDataToForm } from '../../ds/repositories/commands';
import { PropertyForm } from '../../pages/property/ui/context';
import { TitleForm } from '../../pages/title/ui/context';
import useNotification from '../../../../widgets/notifier/ui/hooks/useNitification';
import { QueriesCreateAgreement } from '../../ds/repositories/queries';
import { DEFAULT_RISKS, Risks } from '../../../../../libs/utils/staticData';
import {CALC_AGREEMENT, CALCULATE_AGREEMENT_FINISH, UPLOAD_FILES} from '../../../../../libs/api/commands';
import {
	CalculateAgreementFinishMutation, CalculateAgreementFinishMutationVariables,
	CalculateAgreementMutation,
	CalculateAgreementMutationVariables,
	MeQuery, MeQueryVariables, UploadFilesMutation, UploadFilesMutationVariables
} from '../../../../../libs/api/graphqlTypes';
import { UploadFilesForm } from '../../pages/uploadFiles/ui/context';
import {rightsHandler, useUserRights} from "../../../common/hooks/useUserRights";
import {ME} from "../../../../../libs/api/queries";
import {isMoRegion, isNewRegion} from "../../../../../libs/utils/address";

export type AgreementId = number | null;

type Document = {
	id: string;
	name: string;
	url: string;
	added?: string;
};

type CreateAgreementContext = {
	isEdit: boolean;
	risks: Risks;
	agreementId: AgreementId;
	goBackHandler: () => void;
	refreshFormDataHandler: () => void;
	switchHiddenButtonsHandler: (isHidden: boolean) => void;
	currentStepNumber: number;
	currentStepTitle: string;
	prevStepHandler: () => void;
	nextStepHandler: (event: any) => void;
	onFormSubmit: () => void;
	closeCreateModalHandler: () => void;
	closeDeclarationModalHandler: () => void;
	createModalIsOpen: boolean;
	declarationModalIsOpen: boolean;
	isDisabledNextStep: boolean;
	isDisabledPrevStep: boolean;
	isShowNextStep: boolean;
	isShowPrevStep: boolean;
	isLastStep: boolean;
	isLoading: boolean;
	isDeclarationAvailable: boolean;
	createAgreementAvaiable: boolean;
	calcForm: any;
	expressMode: boolean;
	addendumMode: boolean;
	sharedFiles: any;
	setSharedFiles: (files: any) => void;
	documents: Document[];
	isFieldsDisabled: boolean;
};

export const stepNameById: { title: string; step: AgreementSteps }[] = [
	{
		title: 'Общие сведения о страхователе',
		step: 'contragentStep',
	},
	{
		title: 'Сведения о занятости страхователя',
		step: 'jobStep',
	},
	{
		title: 'Основные положения кредитного договора',
		step: 'bankStep',
	},
	{
		title: 'Страхование жизни, потери трудоспособности залогодателя',
		step: 'insuranceStep',
	},
	{
		title: 'Страхование имущества от гибели, утраты, повреждения',
		step: 'propertyStep',
	},
	{
		title: 'Страхование потери имущества в результате прекращения, ограничения (обременения) права собственности',
		step: 'titleStep',
	},
	{
		title: 'Дополнительные документы к заявке',
		step: 'additionalFilesStep',
	},
];

export type CreateAgreementForm = ContragentsForm & JobForm & BankForm & PropertyForm & TitleForm & UploadFilesForm;

const CreateAgreementContext = createContext<CreateAgreementContext>({} as CreateAgreementContext);

export const useCreateAgreementContext = (): CreateAgreementContext => useContext(CreateAgreementContext);

const CreateAgreementContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const {
		api,
		routes: { createAgreementStep, dashboard, agreementByIdSeller, agreementByIdUnderwriter, contractById, precalculation },
	} = useGlobalContext();
	const { rightsHandler } = useUserRights();
	const { setNotification } = useNotification();
	const navigate = useNavigate();
	const location = useLocation();
	const queriesCreateAgreements = React.useMemo(() => QueriesCreateAgreement(api), [api]);
	const commandsCreateAgreements = React.useMemo(() => CommandsCreateAgreement(api, queriesCreateAgreements), [api, queriesCreateAgreements]);
	const { id } = useParams<{ id?: string }>();
	const formHook = useForm<CreateAgreementForm>({
		mode: 'onBlur',
	});
	const { data: dataUser } = useQuery<MeQuery, MeQueryVariables>(ME);

	const [stateRisks, setRisks] = React.useState<Risks>(DEFAULT_RISKS);
	const [stateSteps, setSteps] = React.useState<AgreementSteps[]>([]);
	const [stateDeclarationAvailable, setDeclarationAvailable] = useState(false);
	const [expressMode, setExpressMode] = useState(false);
	const [createAgreementAvaiable, setCreateAgreementAvaiable] = useState(false);
	const [addendumMode, setAddendumMode] = useState(false);
	const [stateCalcForm, setCalcForm] = useState<any>(null);
	const [stateFieldsDisabled, setFieldsDisabled] = useState<boolean>(false);
	const [stateIsLoading, setIsLoading] = React.useState<boolean>(false);
	const [stateIsHiddenButtons, setIsHiddenButtons] = React.useState<boolean>(false);
	const [stateCreateModalIsOpen, setCreateModalIsOpen] = React.useState<boolean>(false);
	const [stateDeclarationModalIsOpen, setDeclarationModalIsOpen] = React.useState<boolean>(false);
	const [stateDocuments, setDocuments] = React.useState<Document[]>([]);
	const [sharedFilesState, setSharedFilesState] = React.useState<any>(null);
	const [stateAgrIsn, setAgrIsn] = React.useState<number|null>(null);

	const [calcAgreement, { loading: loadingCalcAgreement }] = useMutation<CalculateAgreementMutation, CalculateAgreementMutationVariables>(CALC_AGREEMENT);
	const [uploadFiles, { loading: loadingUploadFiles }] = useMutation<UploadFilesMutation, UploadFilesMutationVariables>(UPLOAD_FILES);
	const [calcAgreementFinish, { loading: loadingCalcAgreementFinish }] = useMutation<CalculateAgreementFinishMutation, CalculateAgreementFinishMutationVariables>(CALCULATE_AGREEMENT_FINISH);

	const agreementId: AgreementId = Number.isNaN(Number(id)) ? null : Number(id);
	const currentStep: AgreementSteps = location.pathname.split('/')[3] as AgreementSteps;
	const isFirstStep: boolean = currentStep === stateSteps[0];
	const isLastStep: boolean = currentStep === stateSteps[stateSteps.length - 1];
	const currentStepTitle: string = stepNameById.find(({ step }) => step === currentStep)?.title || 'no step';
	const currentStepNumber: number = stateSteps.indexOf(currentStep) + 1;
	const isAllFilled: boolean = ![...(formHook.getValues('coBorrowers') || []), ...(formHook.getValues('contragents') || [])].filter(person => !person?.fillingType).length;

	const refreshFormDataHandler = React.useCallback((callback?: () => void) => {
		if (!agreementId) return setNotification({ type: 'error', text: 'no id' });
		queriesCreateAgreements
			.getAgreementById(agreementId)
			.then(({ data }) => {
				if (!data) return setNotification({ type: 'error', text: 'no agreement data' });
				const formData = formatAgreementDataToFormData(data);
				setDataToForm(formData, formHook);
				setCalcForm(data.loadAgreement?.calcForm);
				setRisks({
					riskLife: !!data.loadAgreement?.calcForm?.riskLife,
					riskProperty: !!data.loadAgreement?.calcForm?.riskProperty,
					riskTitle: !!data.loadAgreement?.calcForm?.riskTitle,
				});
				setSteps((data.loadAgreement?.steps as AgreementSteps[]) || []);
				setDeclarationAvailable(data.loadAgreement?.declarationAvailable || false);
				setExpressMode(data.loadAgreement?.isExpressMode || false);
				setAddendumMode(data.loadAgreement?.isAddendumMode || false);
				setCreateAgreementAvaiable(data.loadAgreement?.createAgreementAvailable || false);
				if(callback !== undefined){
					callback();
				}
				const documents: Document[] = (data?.loadAgreement?.files || []).map(document => ({
					id: document?.id || '',
					name: (document?.filename || '').length > 35 ? `${(document?.filename || '').substring(0, 35)}...` : document?.filename || '',
					url: document?.url || '',
					added: '',
				}));
				setDocuments(documents);
				const agrIsn = data.loadAgreement?.isn ? parseInt(data.loadAgreement?.isn, 10) : null
				setAgrIsn(agrIsn);
				setFieldsDisabled(data.loadAgreement?.calcForm?.policyholder?.anketaSigned || !!agrIsn);
			})
			.catch(error => setNotification({ type: 'error', text: error }));
	}, [agreementId, formHook, queriesCreateAgreements, setNotification]);

	const switchHiddenButtonsHandler = React.useCallback((isHidden: boolean) => setIsHiddenButtons(isHidden), []);

	const getRedirectUrlByIsn = (agrIsn: number) =>
		agrIsn > 0 ?
			contractById(String(agrIsn))
			: (rightsHandler('underwritingPage') ? agreementByIdUnderwriter(String(agrIsn)) : agreementByIdSeller(String(agrIsn)))

	React.useEffect(() => {
		if(stateAgrIsn){
			navigate(getRedirectUrlByIsn(stateAgrIsn));
		}
		refreshFormDataHandler();
		window.scrollTo({
			left: 0,
			top: 0,
			behavior: 'smooth',
		});

	}, [currentStep, refreshFormDataHandler, stateAgrIsn]);

	const closeCreateModalHandler = React.useCallback(() => setCreateModalIsOpen(false), []);
	const closeDeclarationModalHandler = () => {
		setDeclarationModalIsOpen(false);
		refreshFormDataHandler(() => {
			if (!stateRisks.riskLife && stateCalcForm?.policyholder?.fillingType) {
				setCreateModalIsOpen(true);
			}
		});
	};

	const prevStepHandler = React.useCallback(() => {
		if (!agreementId) return setNotification({ type: 'error', text: 'no agreement id' });
		const findNextStep: AgreementSteps = stateSteps[stateSteps.indexOf(currentStep) - 1];
		if(stateFieldsDisabled){
			return navigate(createAgreementStep(String(agreementId), findNextStep));
		}
		setIsLoading(true);
		commandsCreateAgreements.saveAgreement({
			id: agreementId,
			step: findNextStep,
			successCallback: () => {
				navigate(createAgreementStep(String(agreementId), findNextStep));
				setIsLoading(false);
			},
			errorCallback: error => {
				setNotification({ type: 'error', text: error });
				setIsLoading(false);
			},
		});
	}, [agreementId, commandsCreateAgreements, createAgreementStep, currentStep, navigate, setNotification, stateSteps]);

	const nextStepHandler = (event: any) => {
		event.stopPropagation();
		if (!agreementId) return setNotification({ type: 'error', text: 'no agreement id' });
		const findNextStep: AgreementSteps = stateSteps[stateSteps.indexOf(currentStep) + 1];
		navigate(createAgreementStep(String(agreementId), findNextStep));
	}

	const calculateAgreementFinish = (agreementId: number) => {
		console.info('Calculate finish...');
		calcAgreementFinish({
			variables: {
				id: agreementId,
			},
		})
			.then((data) => {
				const agrIsn = data.data?.calculateAgreementFinish;
				if(agrIsn && agrIsn > 0){
					console.info('Create agreement', agrIsn);
				}
				setNotification({ type: 'success', text: (agrIsn && agrIsn > 0) ? 'Договор успешно создан' : 'Заявка успешно создана' });

				const nextUrl = agrIsn ? getRedirectUrlByIsn(agrIsn) : 	dashboard();

				navigate(nextUrl);
			})
			.catch(error => setNotification({ type: 'error', text: error }));
	}

	const calculateAgreementUploadFiles = (agreementId: number) => {
		console.info('Upload files...');
		uploadFiles({
			variables: {
				id: agreementId,
			},
		})
			.then((data) => {
				const uploadDone = data.data?.uploadFiles;
				if(uploadDone){
					calculateAgreementFinish(agreementId);
				} else {
					calculateAgreementUploadFiles(agreementId);
				}
			})
			.catch(error => setNotification({ type: 'error', text: error }));
	}

	const onFormSubmit = formHook.handleSubmit(data => {
		if (!agreementId) return setNotification({ type: 'error', text: 'no agreement id' });
		if (isLastStep && !stateRisks.riskLife && !stateCalcForm?.policyholder?.fillingType && !addendumMode && !expressMode) {
			setDeclarationModalIsOpen(true);
			return;
		}
		if (isLastStep && !stateCreateModalIsOpen && !addendumMode) return setCreateModalIsOpen(true);

		const findNextStep: AgreementSteps = stateSteps[stateSteps.indexOf(currentStep) + 1];
		if (data.isHaveCoBorrower === 'true' && (data.coBorrowers || []).length === 0) return formHook.setError('isHaveCoBorrower', { message: 'Укажите хотя бы одного созаемщика' });

		const dealCountry: any = data?.dealCountry;

		let isRegionError = false;

		if(!!dealCountry && dealCountry?.data?.city){
			if(isMoRegion(dealCountry) && dataUser?.me?.user?.group?.is_filial || isNewRegion(dealCountry)){
				isRegionError = true;
			}
		}

		if(data?.contragents?.length > 0){
			data.contragents.forEach((contragent: any) => {
				if(contragent?.addr1 && isNewRegion(contragent?.addr1)){
					isRegionError = true;
				}
			})
		}

		if(data?.properties?.length > 0){
			data.properties.forEach((property: any) => {
				if(property?.pledgeAddress && isNewRegion(property?.pledgeAddress)){
					isRegionError = true;
				}
			})
		}
		if(isRegionError){
			return setNotification({type: "error", text: "Расчет по выбранным параметрам невозможен. Обратитесь к куратору!"});
		}

		setIsLoading(true);

		/*if(stateFieldsDisabled && !isLastStep){
			return navigate(createAgreementStep(String(agreementId), findNextStep));
		}*/

		commandsCreateAgreements.saveAgreement({
			id: agreementId,
			currentFormData: data,
			step: findNextStep,
			successCallback: () => {
				setIsLoading(false);
				if (!isLastStep) return navigate(createAgreementStep(String(agreementId), findNextStep));
				console.info('Calculate...')
				calcAgreement({
					variables: {
						id: agreementId,
					},
				})
					.then((data) => {
						const calcIsn = data.data?.calculateAgreement;
						console.info('Calculate done', calcIsn);
						calculateAgreementUploadFiles(agreementId);
					})
					.catch(error => setNotification({ type: 'error', text: error }));
			},
			errorCallback: error => {
				setIsLoading(false);
				setNotification({ type: 'error', text: error });
			},
		});
	});



	const value: CreateAgreementContext = React.useMemo(
		() => ({
			agreementId,
			isEdit: false,
			risks: stateRisks,
			goBackHandler: () => {
				navigate(precalculation());
			},
			currentStepNumber,
			currentStepTitle,
			prevStepHandler,
			nextStepHandler,
			onFormSubmit,
			switchHiddenButtonsHandler,
			isDisabledNextStep: currentStep === 'insuranceStep' && !isAllFilled,
			isDisabledPrevStep: false,
			refreshFormDataHandler,
			isLastStep,
			isShowNextStep: !stateIsHiddenButtons,
			isShowPrevStep: !isFirstStep && !stateIsHiddenButtons,
			isLoading: stateIsLoading || loadingCalcAgreement || loadingUploadFiles || loadingCalcAgreementFinish,
			closeCreateModalHandler,
			closeDeclarationModalHandler,
			createModalIsOpen: stateCreateModalIsOpen,
			declarationModalIsOpen: stateDeclarationModalIsOpen,
			isDeclarationAvailable: stateDeclarationAvailable,
			expressMode,
			createAgreementAvaiable,
			addendumMode,
			calcForm: stateCalcForm,
			sharedFiles: sharedFilesState,
			setSharedFiles: setSharedFilesState,
			documents: stateDocuments,
			isFieldsDisabled: stateFieldsDisabled,
		}),
		[
			agreementId,
			closeCreateModalHandler,
			currentStep,
			currentStepNumber,
			currentStepTitle,
			isAllFilled,
			isFirstStep,
			isLastStep,
			loadingCalcAgreement,
			onFormSubmit,
			prevStepHandler,
			refreshFormDataHandler,
			stateCreateModalIsOpen,
			stateIsHiddenButtons,
			stateIsLoading,
			stateRisks,
			switchHiddenButtonsHandler,
			stateDeclarationAvailable,
			stateDeclarationModalIsOpen,
			stateDocuments,
		]
	);

	return (
		<CreateAgreementContext.Provider value={value}>
			<FormProvider {...formHook}>{children}</FormProvider>
		</CreateAgreementContext.Provider>
	);
};

export default React.memo(CreateAgreementContextProvider);
