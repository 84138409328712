import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ValidationProps } from '../types';
import { stringToNumber } from '../../utils';
import { IDaDataValue } from '../../daData/types';

dayjs.extend(customParseFormat);

const regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// const regDate = /^\d{2}[./-]\d{2}[./-]\d{4}$/;
const regSlug = /^[_a-z][_a-zA-Z0-9]{1,40}$/;
const regCadastralNumber = /[^0-9:/\-_\\]/;
const regContractNumber = /[^a-zA-Z0-9а-яА-ЯёЁ./\-_\\]/;

const checkDateFormat = (date: Date): string | undefined => {
	if (date && !dayjs(date).isValid()) return 'Неверный формат даты';
	return undefined;
};

export const fieldsValidate = (props: ValidationProps): string | undefined => {
	const { value, validationType, maxValue, minValue, maxDate, minDate, minLength, maxLength, regexpPattern, minMaxMessage } = props;
	if (value === undefined || !validationType) return undefined;
	const trimValue = String(value).trim();
	const valueToNumber: number = Number(stringToNumber(trimValue));
	const clearValue = trimValue.replace(/[^\d;]/g, '');
	const fullNameArray: string[] = ((value as IDaDataValue)?.value || '').trim().split(' ');
	switch (validationType) {
		case 'pattern':
			if (!regexpPattern) return 'Осутствует паттерн';
			if (regexpPattern.test(trimValue)) return undefined;
			return 'Введенное значение не соответсвует паттерну';
		case 'phone':
			if (trimValue && clearValue.length !== 11)
				return 'Введите телефон полностью';
			return undefined;
		case 'contragentIsn':
			if(!trimValue) return undefined;
			if(trimValue !== clearValue) return 'Допускаются только цифры';
			if (clearValue.length !== 9) return 'Введите ISN полностью';
			return undefined;
		case 'authDocIssuerCode':
			if(!trimValue) return undefined;
			if (clearValue.length !== 6) return 'Введите код полностью';
			return undefined;
		case 'email':
			if (!trimValue) return undefined;
			if (regEmail.test(trimValue)) return undefined;
			return 'Неверный формат email';
		case 'slug':
			if (!trimValue) return 'Введите slug';
			if (regSlug.test(trimValue)) return undefined;
			return 'Неверный формат slug';
		case 'password':
			if (!trimValue) return 'Введите пароль';
			if (trimValue.length < 6) return 'Введите пароль полностью';
			return undefined;
		case 'fullName':
			if (fullNameArray.length >= 2) return undefined;
			return 'Введите Ф.И.О. полностью';
		case 'addressWithFlat':
			if (!(value as IDaDataValue)?.value) return 'Выберите адрес';
			if (!(value as IDaDataValue)?.data?.flat) return 'Выберите адрес вплоть до квартиры';
			return undefined;
		case 'addressWithCity':
			if (!(value as IDaDataValue)?.value) return 'Выберите адрес';
			if (!(value as IDaDataValue)?.data?.city) return 'Выберите адрес вплоть до населенного пункта';
			return undefined;
		case 'addressWithHouse':
			if (!(value as IDaDataValue)?.value) return 'Выберите адрес';
			if (!(value as IDaDataValue)?.data?.house) return 'Выберите адрес вплоть до дома';
			return undefined;
		case 'cadastralNumber':
			if (regCadastralNumber.test(trimValue)) return 'Может содержать только цифры и разделитель';
			return undefined;
		case 'contractNumber':
			if (trimValue.length > 255) return 'Не может быть длиннее 255 символов';
			if (regContractNumber.test(trimValue)) return 'Не должен содержать спец символов';
			return undefined;
		case 'date':
			return checkDateFormat(value as Date);
		case 'maskFilled':
			if (!trimValue.includes('_')) return undefined;
			return 'Заполните полностью';
		case 'minMax':
			if (Number.isNaN(valueToNumber)) return 'Не число';
			if (typeof minValue === 'number' && valueToNumber < minValue) return (minMaxMessage || `Введенное число меньше минимально допустимого в ${minValue}`);
			if (typeof maxValue === 'number' && valueToNumber > maxValue) return (minMaxMessage || `Введенное число больше максимально допустимого в ${maxValue}`);
			return undefined;
		case 'minMaxDate':
			if (checkDateFormat(value as Date)) return 'Неверный формат даты';
			if (minDate && dayjs(value as Date).isBefore(minDate, 'date') && !dayjs(value as Date).isSame(minDate, 'date')) return `Дата не может быть ранее ${minDate}`;
			if (maxDate && dayjs(maxDate).isBefore(value as Date, 'date') && !dayjs(maxDate).isSame(value as Date, 'date')) return `Дата не может быть позднее ${maxDate}`;
			return undefined;
		case 'minMaxLength':
			if (typeof minLength === 'number' && trimValue.length < minLength) return `Количество символов меньше минимально допустимого в ${minLength}`;
			if (typeof maxLength === 'number' && trimValue.length > maxLength) return `Количество символов больше максимально допустимого в ${maxLength}`;
			return undefined;
		default:
			return 'Unknown validation type';
	}
};
