import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useQuery} from "@apollo/react-hooks";
import {DocsQuery, DocsQueryVariables, DocTypeEnum} from "../../../libs/api/graphqlTypes";
import {DOCS} from "../../../libs/api/queries";
import {RowType} from "../../../libs/uiKit/table/types";
import Button from "../../../libs/uiKit/button";
import {PencilIcon} from "../../../icons/ui/Pencil";
import {stringToMoney} from "../../../libs/uiKit/utils";
import Status from "../../../libs/uiKit/status";
import {getShortName} from "../agreementsLog/ds/commands";
import {StyledAgreementsLogHeader, StyledGroupPath} from "../agreementsLog/ui/styles";
import {Heading2, Text4} from "../../../libs/uiKit/globalStyledComponents";
import MainLayoutIgniter from "../common/mainLayout";
import Table from "../../../libs/uiKit/table";
import {useGlobalContext} from "../../../apps/main/ui/GlobalContext";
import useNotification from "../../widgets/notifier/ui/hooks/useNitification";
import {AgreementSteps} from "../createAgreement/ds/repositories/commands";


const DocsLog: React.FC<{ type: DocTypeEnum }> = ({ type }) => {

  const {
    user: { isSuper },
    routes: { agreementByIdUnderwriter },
  } = useGlobalContext();

  const { setNotification } = useNotification();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState<number>(0);

  const { data: dataDocs, loading: loadingDocs } = useQuery<DocsQuery, DocsQueryVariables>(DOCS, {
    variables: {
      type,
      limit: 10,
      page: currentPage,
    },
    onError: error => setNotification({ type: 'error', text: error }),
  });

  const asmTotal: number = dataDocs?.docs?.total || 0;

  const formatUnderwritersLine = (underwriters: string[]) => (
    underwriters.length > 0
    ? underwriters.join(", ")
    : "Ожидание"
  )

  const rows: RowType[] = (dataDocs?.docs?.data || []).reduce<RowType[]>((prev, doc) => {
    if (!doc) return prev;
    const { id, number, creditSumm, bankName, added, insurerName, user, groupPath, underwriters } = doc;

    return [
      ...prev,
      {
        key: String(id),
        cells: [
          {
            key: 'cell-view',
            content: (
              <Button
                onClick={(): void =>
                  navigate(agreementByIdUnderwriter(String(id)))
                }
                appearance='flag'
                icon={<PencilIcon />}
              />
            ),
          },
          { key: `cell-${id}-number`, content: number },
          { key: `cell-${id}-summ`, content: stringToMoney(creditSumm) },
          { key: `cell-${id}-bank`, content: bankName },
          { key: `cell-${id}-added`, content: added },
          { key: `cell-${id}-insurer`, content: insurerName },
          { key: `cell-${id}-created`, content: `${getShortName(user?.username || '')}` },
          {
            key: `cell-${id}-group`,
            content: (
              <StyledGroupPath>
                {(groupPath || []).map((group, index) => (
                  <Text4 key={`group-${index}-${id}`}>{group}</Text4>
                ))}
              </StyledGroupPath>
            ),
          },
          { key: `cell-${id}-underwriters`, content: formatUnderwritersLine(
              (underwriters || []).map((underwriter) => underwriter?.username || "")
            ) },
        ],
        onClick: (): void => navigate(agreementByIdUnderwriter(String(id))),
      },
    ];
  }, []);

  return (
    <div style={{margin: "1rem 0 4rem 0"}}>
      <Table
        emptyView='Здесь пока ничего нет'
        isLoading={loadingDocs}
        onChange={(page:number) => {
          setCurrentPage(page-1);
        }}
        total={asmTotal}
        rowsPerPage={10}
        selectedPage={currentPage}
        head={{
          cells: [
            {
              key: 'view',
              content: 'просмотр',
              width: 5,
            },
            {
              key: 'number',
              content: '№ заявки',
            },
            {
              key: 'summ',
              content: 'Сумма кредита, RUB',
            },
            {
              key: 'bank',
              content: 'Банк',
            },
            {
              key: 'added',
              content: 'Дата создания',
            },
            {
              key: 'insurer',
              content: 'Заемщик',
            },
            {
              key: 'created',
              content: 'Создана',
            },
            {
              key: 'group',
              content: 'Группа',
            },
            {
              key: 'underwriters',
              content: 'Исполнитель',
            },
          ],
        }}
        rows={rows}
      />
    </div>
  );
}

export default DocsLog;